import '../styles/form.scss';

import { Form, Input, notification, Slider } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Link, useIntl } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';

import { ESTIMATION_FORM_URL } from '../config/forms';
import Button from './button';
import { SubmissionState } from '../types/form';
import { post } from '../rest';
import { generateUniqId } from '../utils/generate-uniq-id';

//icons
import SvgEstimationContainersIcon from '../images/svgs/estimation-page/containers.svg';
import SvgEstimationFencesIcon from '../images/svgs/estimation-page/fences.svg';
import SvgEstimationOtherIcon from '../images/svgs/estimation-page/other.svg';

enum ProjectEstimationGroupId {
  Topic = 'topic'
}

const SINGLE_SELECTION_OPTIONS = [];

const PROJECT_TOPIC_OPTIONS = [
  {
    id: generateUniqId(),
    label: 'estimation.ESTIMATION_PAGE_DESIGNATION_FENCES',
    groupId: ProjectEstimationGroupId.Topic,
    icon: <SvgEstimationFencesIcon />,
    value: 'fences'
  },
  {
    id: generateUniqId(),
    label: 'estimation.ESTIMATION_PAGE_DESIGNATION_OTHER',
    groupId: ProjectEstimationGroupId.Topic,
    icon: <SvgEstimationOtherIcon />,
    value: 'other'
  }
];

const DEFAULT_PROJECT_DETAILS_VALUES = {
  topic: []
};

const EstimationForm: React.FC = () => {
  const [projectDetails, setProjectDetails] = useState<{
    topic: string[];
  }>(DEFAULT_PROJECT_DETAILS_VALUES);

  const [submissionState, setSubmissionState] = useState<
    SubmissionState | undefined
  >(undefined);

  const intl = useIntl();

  const formRef = React.createRef<FormInstance>();

  useEffect(() => {
    switch (submissionState) {
      case SubmissionState.Success:
        formRef.current?.resetFields();
        setProjectDetails(DEFAULT_PROJECT_DETAILS_VALUES);
        notification.success({
          message: intl.formatMessage({ id: 'contact.FORM_SUCCESS_MESSAGE' })
        });
        break;
      case SubmissionState.Error:
        notification.error({
          message: intl.formatMessage({ id: 'contact.FORM_SUCCESS_ERROR' })
        });
        break;
      default:
    }
  }, [submissionState]);

  const pending = submissionState === SubmissionState.Pending;

  const addOrRemoveValue = option => {
    const currentValues = projectDetails?.[option.groupId];

    return currentValues.includes(option.value)
      ? currentValues.filter(value => value !== option.value)
      : [...currentValues, option.value];
  };

  const handleOptionSelect = option => {
    setProjectDetails({
      ...projectDetails,
      [option.groupId]: SINGLE_SELECTION_OPTIONS.includes(option.groupId)
        ? [option.value]
        : addOrRemoveValue(option)
    });
  };

  const isOptionSelected = option =>
    projectDetails?.[option.groupId]?.includes(option.value);

  return (
    <div>
      <div className="form-group" data-aos="fade-up">
        <h4 className="centered-vertical">
          <span className="estimation-num">01</span>
          {intl.formatMessage({
            id: 'estimation.ESTIMATION_PAGE_TOPIC_FORM_LABEL'
          })}
        </h4>
        <div className="form-group-content">
          <div className="form-group-content">
            <div className="estimation-options">
              {PROJECT_TOPIC_OPTIONS.map(option => (
                <div
                  className={`estimation-option ${
                    isOptionSelected(option) ? 'selected' : ''
                  }`}
                  key={option.id}
                  onClick={() => handleOptionSelect(option)}
                >
                  {option.icon}
                  <p className="label">
                    {intl.formatMessage({
                      id: option.label
                    })}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="form-group" data-aos="fade-up">
        <h4 className="centered-vertical">
          <span className="estimation-num">02</span>
          {intl.formatMessage({
            id: 'estimation.ESTIMATION_PAGE_USER_DETAILS_FORM_LABEL'
          })}
        </h4>
        <div className="form-group-content">
          <Form
            onSubmitCapture={async () => {
              const fields = formRef.current?.getFieldsValue() ?? {};

              try {
                await formRef.current?.validateFields();
              } catch (error) {
                if (
                  error.errorFields !== undefined &&
                  error.errorFields.length > 0
                ) {
                  return;
                }
              }

              try {
                setSubmissionState(SubmissionState.Pending);
                await post(ESTIMATION_FORM_URL, {
                  ...fields,
                  ...projectDetails
                });
                setSubmissionState(SubmissionState.Success);
              } catch (error) {
                setSubmissionState(SubmissionState.Error);
              }
            }}
            layout="vertical"
            ref={formRef}
            initialValues={{ remember: true }}
          >
            <Form.Item
              label={intl.formatMessage({
                id: 'contact.FORM_FIELD_NAME_LABEL'
              })}
              name="name"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'contact.FORM_FIELD_ERROR_MESSAGE_NAME'
                  })
                }
              ]}
            >
              <Input disabled={pending} />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: 'contact.FORM_FIELD_EMAIL_LABEL'
              })}
              name="email"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'contact.FORM_FIELD_ERROR_MESSAGE_EMAIL'
                  })
                }
              ]}
            >
              <Input type="email" disabled={pending} />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: 'contact.FORM_FIELD_MESSAGE_LABEL'
              })}
              name="message"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'contact.FORM_FIELD_ERROR_MESSAGE_MSG'
                  })
                }
              ]}
            >
              <Input.TextArea
                placeholder={intl.formatMessage({
                  id: 'contact.FORM_FIELD_MSG_FIELD_PLACEHOLDER'
                })}
                disabled={pending}
              />
            </Form.Item>

            <p className="privacy-policy-notice">
              {intl.formatMessage({ id: 'contact.FORM_PRIVACY_POLICY_NOTICE' })}
              {` `}
              <Link className="link item" to="/privacy-policy">
                {intl.formatMessage({
                  id: 'PRIVACY_POLICY_LABEL'
                })}
              </Link>
              .
            </p>

            <Form.Item>
              <Button
                htmlType="submit"
                modeClassName="primary"
                disabled={pending}
                loading={pending}
                label={intl.formatMessage({
                  id: 'contact.SUBMIT_BUTTON_LABEL'
                })}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EstimationForm;
