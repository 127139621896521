import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useIntl } from 'gatsby-plugin-intl';
import EstimationForm from '../components/estimation-form';
import { generateUniqId } from '../utils/generate-uniq-id';
import '../styles/contact-page.scss';
import { Link } from 'gatsby-plugin-intl';

//icons
import SvgArrowGoBack from '../images/svgs/estimation-page/arrow-right.svg';
import SvgFeedback24Icon from '../images/svgs/estimation-page/feedback-24.svg';
import SvgCodeReviewIcon from '../images/svgs/estimation-page/code-review.svg';
import SvgCostAndTimeEstimationIcon from '../images/svgs/estimation-page/cost-and-time-estimation.svg';

const ESTIMATION_PROCESS_ROWS = [
  {
    id: generateUniqId(),
    icon: <SvgFeedback24Icon />,
    title: 'estimation.ESTIMATION_PAGE_PROCESS_FEEDBACK_TITLE',
    subTitle: 'estimation.ESTIMATION_PAGE_PROCESS_FEEDBACK_SUB_TITLE'
  },
  {
    id: generateUniqId(),
    icon: <SvgCostAndTimeEstimationIcon />,
    title: 'estimation.ESTIMATION_PAGE_COST_TIME_TITLE',
    subTitle: 'estimation.ESTIMATION_PAGE_COST_TIME_SUB_TITLE'
  }
];

const ContactPage = ({ location }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'estimation.SEO_TITLE' })} />
      <main id="estimation-page">
        <section className="section-container section-light">
          <div className="container">
            <div data-aos="fade-up">
              <div className="arrow-back d-flex centered-vertical">
                <Link
                  to={`/${location?.state?.prevPath?.split('/')[2] ?? ''}`}
                  className="d-flex"
                >
                  <SvgArrowGoBack />
                  {intl.formatMessage({
                    id: 'estimation.ESTIMATION_PAGE_GO_BACK_BUTTON_TEXT'
                  })}
                </Link>
              </div>

              <h2>
                {intl.formatMessage({ id: 'estimation.ESTIMATION_PAGE_TITLE' })}
              </h2>
            </div>

            <div className="row" data-aos="fade-up">
              {ESTIMATION_PROCESS_ROWS.map(row => (
                <div className="col estimation-box" key={row.id}>
                  <div className="estimation-box-icon">{row.icon}</div>
                  <div>
                    <h4>{intl.formatMessage({ id: row.title })}</h4>
                    <p>{intl.formatMessage({ id: row.subTitle })}</p>
                  </div>
                </div>
              ))}
            </div>

            <EstimationForm />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ContactPage;
